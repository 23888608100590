<template>
  <div class="upload-image-box">
    <div class="avatar-success-container">
      <template v-if="imageList.length">
        <div
          v-for="(item, index) in imageList"
          :key="index"
          style="
            position: relative;
            height: fit-content;
            margin: 5px;
            border: 1px dashed #d9d9d9;
          "
        >
          <el-image
            class="avatar"
            :style="`width: ${width};height: ${height};`"
            :src="item.ysImg"
            :preview-src-list="[item.img]"
            :initial-index="index"
            fit="contain"
          />
          <div
            v-if="clearable"
            class="del-img"
            @click.stop.prevent="removePhotoUrl(index)"
          >
            删除
          </div>
        </div>
      </template>
      <el-upload
        v-if="imageList.length < props.limit"
        v-loading="isUpLoading"
        class="avatar-uploader"
        :action="`${baseUrl}/manager/warehouse/uploadImage`"
        :headers="{ Authorization: `Bearer ${$store.getters.token}` }"
        :show-file-list="false"
        :on-success="handlePhotoUrlSuccess"
        :on-error="handlePhotoUrlError"
        :on-progress="handlePhotoUrlProgress"
        :limit="props.limit"
      >
        <el-icon
          class="avatar-uploader-icon"
          :style="`width: ${width};height: ${height};line-height: ${height};`"
          ><plus
        /></el-icon>
      </el-upload>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'

const props = defineProps({
  modelValue: {
    // 图片数据
    typeof: String || Array,
    require: true
  },
  limit: {
    // 图片数量
    type: Number,
    default: 1
  },
  format: {
    // 图片输出格式 array / string
    type: String,
    default: 'string'
  },
  clearable: {
    // 显示删除按钮
    type: Boolean,
    default: true
  },
  /* actionRoute: {
    type: String,
    default: 'lottery_model'
  } */
  width: {
    // 宽
    type: String,
    default: '100px'
  },
  height: {
    // 高
    type: String,
    default: '100px'
  }
})

const emits = defineEmits(['update:modelValue'])
const imageList = ref([])
const baseUrl = process.env.VUE_APP_BASE_API

// 上传缩略图
const handlePhotoUrlSuccess = res => {
  try {
    if (!res.success) {
      ElMessage.error('上传图片失败')
      return
    }
    imageList.value = imageList.value.concat(res.data)
    emits('update:modelValue', imageFormat())
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    isUpLoading.value = false
  }
}

// 上传图片失败
const handlePhotoUrlError = () => {
  isUpLoading.value = false
  ElMessage.error('上传图片失败')
}

// 上传图片中的钩子
const isUpLoading = ref(false)
const handlePhotoUrlProgress = () => {
  if (!isUpLoading.value) {
    isUpLoading.value = true
  }
}

// 删除缩略图
const removePhotoUrl = index => {
  imageList.value.splice(index, 1)
  emits('update:modelValue', imageFormat())
}

// 组件按照格式输出的图片数据
const imageFormat = () => {
  let data = imageList.value
  if (props.format === 'string' && Array.isArray(data)) {
    data = data.join(',')
  }
  return data
}

watch(
  () => props.modelValue,
  val => {
    if (val) {
      if (props.format === 'string') {
        imageList.value = props.limit > 1 ? val.split(',') : [val]
      } else {
        imageList.value = val
      }
    } else {
      imageList.value = []
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.upload-image-box {
  display: flex;
  flex-wrap: wrap;
}
.avatar-success-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  // width: 178px;
  // height: 178px;
  position: relative;
  font-size: 0;

  .avatar {
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 6px;
  }
  .del-img {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
}
.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  margin: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
}
::v-deep .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
