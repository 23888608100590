<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="1200px"
    draggable
    destroy-on-close
    :z-index="2010"
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        :rules="formRules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="banner名称" prop="title" required>
              <el-input
                v-model.trim="form.title"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="添加人">
              <el-input
                v-model="form.adduser"
                readonly
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="链接地址" prop="url">
          <el-input v-model.trim="form.url" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="排序">
          <el-input v-model="form.xu" :min="0" type="number"></el-input>
        </el-form-item>

        <el-form-item label="启用">
          <el-radio-group v-model="form.enable">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div class="photo-container">
        <el-form :model="form" label-width="80px" label-position="top">
          <el-form-item label="图片">
            <UploadImage
              v-model="form.bannerImage"
              width="200px"
              height="200px"
              format="array"
            ></UploadImage>
            <!-- <el-input v-model="form.bannerImage" placeholder="图片"></el-input> -->
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed, onMounted } from 'vue'
import * as bannerApi from '@/api/banner.js'
import { ElMessage } from 'element-plus'
import UploadImage from '@/components/UploadImage/index.vue'
import { rules } from '../data.js'

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  },
  lanmu: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return JSON.stringify(props.info) === '{}' ? '创建' : '修改'
})

// 数据源
const form = ref({
  xu: 0,
  enable: '1'
})
// 取消
const closed = () => {
  form.value = {
    xu: 0,
    enable: '1'
  }
  emits('update:modelValue', false)
}

watch(
  () => form.value.bannerImage,
  val => {
    form.value.banner = val ? val[0].img : ''
  }
)

// 提交事件
const submitLoading = ref(false)
const ruleFormRef = ref(null)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (!valid) {
      ElMessage.error(fields[Object.keys(fields)[0]][0].message)
      return
    }

    submitLoading.value = true
    form.value.lanmu = props.lanmu
    // console.log('form.value :>> ', form.value)
    try {
      form.value.id
        ? await bannerApi.updateBanner(form.value)
        : await bannerApi.addBanner(form.value)
      ElMessage.success('更新成功')
      emits('updateClient')
      closed()
    } finally {
      submitLoading.value = false
    }
  })
}

// 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    form.value = JSON.parse(JSON.stringify(props.info))
    if (props.info.banner) {
      form.value.bannerImage = [
        { img: form.value.banner, ysImg: form.value.banner }
      ]
    }
    if (props.info.geoJson?.coordinates?.length > 0) {
      form.value.longitude = props.info.geoJson.coordinates[0]
      form.value.latitude = props.info.geoJson.coordinates[1]
    }
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    loading.value = false
  }
}
watch(
  [() => props.info, () => props.modelValue],
  async ([infoVal, modelValueVal]) => {
    if (JSON.stringify(infoVal) !== '{}') getDetail()
  }
)

const formRules = rules
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
.photo-container {
  width: 255px;
  margin-left: 10px;
}
</style>
