import request from '@/utils/request'

const baseUrl = '/manager/config'

/**
 * 获取列表
 */
export const bannerList = data => {
  return request({
    url: `${baseUrl}/getBanner`,
    params: data
  })
}

/**
 * 创建
 */
export const addBanner = data => {
  return request({
    url: baseUrl,
    method: 'POST',
    data
  })
}

/**
 * 更新
 */
export const updateBanner = data => {
  return request({
    url: baseUrl,
    method: 'PUT',
    data
  })
}

/**
 * 删除
 */
export const deleteBanner = id => {
  return request({
    url: `${baseUrl}/${id}`,
    method: 'DELETE'
  })
}
