<template>
  <div class="user-manage-container">
    <el-card class="header">
      <el-radio-group v-model="lanmuRadio">
        <el-radio
          v-for="item in lanmuRadioOption"
          :key="item.value"
          :label="item.value"
        >
          {{ item.label }}
        </el-radio>
      </el-radio-group>
    </el-card>

    <el-card>
      <div class="table-head-box">
        <el-button
          style="float: right"
          :icon="Refresh"
          size="small"
          round
          @click="getListData"
        ></el-button>
        <el-button
          v-permission="['addBanner']"
          class="button"
          type="primary"
          size="small"
          @click="handleCreate"
        >
          新增
        </el-button>
        <el-button
          v-permission="['deleteBanner']"
          class="button"
          type="danger"
          size="small"
          :disabled="!selectedIndexs.length"
          @click="handleAllDelete"
        >
          批量删除
        </el-button>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="id"
          label="ID"
          width="150"
          align="center"
          fixed="left"
        />
        <el-table-column
          prop="title"
          label="banner名称"
          min-width="160"
          align="center"
          fixed="left"
        />
        <el-table-column label="图片" width="150" align="center">
          <template #default="{ row }">
            <el-image
              style="width: 100px; height: 100px; border-radius: 6px"
              :src="row.banner"
              :fit="'contain'"
              :preview-src-list="[row.banner]"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="url"
          label="链接地址"
          min-width="250"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="adduser"
          label="添加人"
          min-width="120"
          align="center"
        />
        <el-table-column
          prop="lanmu"
          label="所属栏目"
          min-width="120"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column min-width="150">
          <template #header>
            <div style="display: flex;align-items:center;">
              排序
              <Edit class="el-icon" style="margin-left: 5px;" />
            </div>
          </template>
          <template #default="{ row }">
            <editOrderNumber
              :modelValue="row.xu"
              @submit="handleEditOrderNumber($event, row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="更新时间" min-width="180">
          <template #default="{ row }">
            {{ $filters.dateTimeFilter(row.updateTime) }}
          </template>
        </el-table-column>

        <el-table-column label="状态" width="100">
          <template #default="{ row }">
            <el-tag :type="row.enable == 1 ? 'success' : 'danger'">
              {{ row.enable == 1 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="170">
          <template #default="{row}">
            <el-button
              v-permission="['editBanner']"
              type="primary"
              size="small"
              @click="handleUpdate(row)"
            >
              编辑
            </el-button>

            <el-dropdown style="margin-left: 12px;">
              <el-button size="small">
                <span class="el-dropdown-link">
                  更多 <ArrowDown class="el-icon" style="margin-left: 5px;" />
                </span>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu class="user-dropdown">
                  <el-dropdown-item>
                    <el-button
                      v-permission="['deleteBanner']"
                      type="danger"
                      size="small"
                      style="width: 72px"
                      @click="handleDelete(row)"
                    >
                      删除
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <create-dialog
      v-model="createDialogVisible"
      :info="editSelectClient"
      :lanmu="currentLanmu"
      @updateClient="getListData"
    ></create-dialog>
  </div>
</template>

<script setup>
import { ref, onActivated, watch, computed } from 'vue'
import { useStore } from 'vuex'
import * as bannerApi from '@/api/banner.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import { ArrowDown, Refresh, Edit } from '@element-plus/icons-vue'
import CreateDialog from './components/create.vue'
import editOrderNumber from '@/components/editOrderNumber/editOrderNumber.vue'

const store = useStore()

// 数据相关
const tableData = ref([])
const multipleTable = ref(null)
const loading = ref(false)

const lanmuRadio = ref(1)
const lanmuRadioOption = [
  {
    label: '首页轮播1',
    value: 1
  },
  {
    label: '首页轮播2',
    value: 2
  },
  {
    label: '类目',
    value: 3
  }
]
const currentLanmu = computed(() => {
  const index = lanmuRadioOption.findIndex(
    item => item.value === lanmuRadio.value
  )
  return lanmuRadioOption[index].label
})
watch(
  () => lanmuRadio.value,
  val => {
    getListData()
  }
)

// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await bannerApi.bannerList({ lanmu: currentLanmu.value })
  tableData.value = result
  loading.value = false
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 新增点击事件
const createDialogVisible = ref(false)
const handleCreate = () => {
  createDialogVisible.value = true
}
// 修改点击事件
const editSelectClient = ref({})
const handleUpdate = row => {
  editSelectClient.value = row
  createDialogVisible.value = true
}
watch(createDialogVisible, val => {
  if (!val) editSelectClient.value = {}
})

/**
 * 处理删除
 */
const handleDelete = row => {
  ElMessageBox.confirm('确定要删除 ' + row.title + ' 的信息吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deleteFn([row.id])
  })
}
/**
 * 批量删除
 */
const handleAllDelete = () => {
  ElMessageBox.confirm('是否批量删除此信息吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(async () => {
    const ids = selectedIndexs.value.map(item => item.id)
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index]
      await deleteFn([id], index === ids.length - 1)
    }
  })
}
/**
 * 处理删除请求
 */
const deleteFn = async (ids, refresh = true) => {
  try {
    await bannerApi.deleteBanner(ids)
    if (refresh) {
      ElMessage.success('删除成功')
      // 重新渲染数据
      getListData()
      multipleTable.value.clearSelection()
    }
  } catch (error) {}
}

const handleEditOrderNumber = async ($event, row) => {
  loading.value = true
  try {
    const res = await bannerApi.updateBanner({
      ...row,
      xu: $event
    })
    ElMessage.success('更新成功')
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.user-manage-container {
  .header {
    margin-bottom: 22px;
    text-align: left;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  ::v-deep .el-tag {
    margin-right: 6px;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
</style>
